﻿.block {
    position: relative;
    z-index: 10;
    padding: 40px 15px 15px 15px;
    box-shadow: 2px 2px 15px rgba($black, .3);

    &__header {
        .title {
        }
    }

    &__body {
        margin-top: 30px;

        .controls {
            margin-top: 50px;
        }
    }

    @media (min-width: $breakpoint-xl) {
        &:before {
            content: attr(data-product);
            font-family: $font-bold;
            @include font-size(110);
            letter-spacing: -10px;
            color: rgba($brand-primary, 0.5);
            mix-blend-mode: saturation;
            line-height: 1;
            position: absolute;
            bottom: calc(100% - 4rem);
            left: 75%;
            white-space: nowrap;
            pointer-events: none;
            width: 40vw;
        }
    }
}


.block-controls {
    &__top {
        position: relative;
        z-index: 20;
        padding-bottom: 10px;
    }

    &__bottom {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
    }

    @media (min-width: $breakpoint-md) {
        &__bottom {
            justify-content: flex-end;
        }
    }
}
