﻿.pagination {
    margin-top: 25px;

    .page-item {
        .page-link {
            padding: 5px 10px;
            color: $brand-primary;
        }

        &.disabled {
            .page-link {
                color: $medium-grey;
            }
        }
    }
}
