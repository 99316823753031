﻿$state-processing: #c8c8c8;
$state-confirmed: #1fc017;
$state-accepted: $state-confirmed;
$state-rejected: #e46010;
$state-canceled: #a70b0b;
$state-completed: $state-confirmed;



.state-indicator {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: $state-processing;
    margin-right: 5px;

    &--processing {
        background-color: $state-processing;
    }

    &--confirmed {
        background-color: $state-confirmed;
    }

    &--accepted {
        background-color: $state-accepted;
    }

    &--canceled {
        background-color: $state-canceled;
    }

    &--completed {
        background-color: $state-completed;
    }
}