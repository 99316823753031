﻿.admin-dashboard {
}

.admin-nav {
    padding: 30px;
    height: 100%;
}

.admin-body {
    padding: 30px;
    height: 100%;
}
