@font-face {
  font-family: ProximaNovaA-Bold;
  font-display: auto;
  src: url("../../fonts/ProximaAlt/ProximaNovaA-Bold.woff2") format("woff2"), url("../../fonts/ProximaAlt/ProximaNovaA-Bold.woff") format("woff"), url("../../fonts/ProximaAlt/ProximaNovaA-Bold.ttf") format("truetype"); }

@font-face {
  font-family: ProximaNovaA-Light;
  font-display: auto;
  src: url("../../fonts/ProximaAlt/ProximaNovaA-Light.woff2") format("woff2"), url("../../fonts/ProximaAlt/ProximaNovaA-Light.woff") format("woff"), url("../../fonts/ProximaAlt/ProximaNovaA-Light.ttf") format("truetype"); }

@font-face {
  font-family: ProximaNovaA-Regular;
  font-display: auto;
  src: url("../../fonts/ProximaAlt/ProximaNovaA-Regular.woff2") format("woff2"), url("../../fonts/ProximaAlt/ProximaNovaA-Regular.woff") format("woff"), url("../../fonts/ProximaAlt/ProximaNovaA-Regular.ttf") format("truetype"); }

@font-face {
  font-family: ProximaNovaA-Semibold;
  font-display: auto;
  src: url("../../fonts/ProximaAlt/ProximaNovaA-Semibold.woff2") format("woff2"), url("../../fonts/ProximaAlt/ProximaNovaA-Semibold.woff") format("woff"), url("../../fonts/ProximaAlt/ProximaNovaA-Semibold.ttf") format("truetype"); }

.embed-responsive-none:before {
  padding-top: 100vh; }

.embed-responsive-21by9:before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-3by2::before {
  padding-top: 66.66667%; }

@media (min-width: 576px) {
  .embed-responsive-sm-none:before {
    padding-top: 100vh; }
  .embed-responsive-sm-21by9:before {
    padding-top: 42.85714%; }
  .embed-responsive-sm-16by9::before {
    padding-top: 56.25%; }
  .embed-responsive-sm-4by3::before {
    padding-top: 75%; }
  .embed-responsive-sm-1by1::before {
    padding-top: 100%; }
  .embed-responsive-sm-3by2::before {
    padding-top: 66.66667%; } }

@media (min-width: 768px) {
  .embed-responsive-md-none:before {
    padding-top: 100vh; }
  .embed-responsive-md-21by9:before {
    padding-top: 42.85714%; }
  .embed-responsive-md-16by9::before {
    padding-top: 56.25%; }
  .embed-responsive-md-4by3::before {
    padding-top: 75%; }
  .embed-responsive-md-1by1::before {
    padding-top: 100%; }
  .embed-responsive-md-3by2::before {
    padding-top: 66.66667%; } }

@media (min-width: 992px) {
  .embed-responsive-lg-none:before {
    padding-top: 100vh; }
  .embed-responsive-lg-21by9:before {
    padding-top: 42.85714%; }
  .embed-responsive-lg-16by9::before {
    padding-top: 56.25%; }
  .embed-responsive-lg-4by3::before {
    padding-top: 75%; }
  .embed-responsive-lg-1by1::before {
    padding-top: 100%; }
  .embed-responsive-lg-3by2::before {
    padding-top: 66.66667%; } }

@media (min-width: 1200px) {
  .embed-responsive-xl-none:before {
    padding-top: 100vh; }
  .embed-responsive-xl-21by9:before {
    padding-top: 42.85714%; }
  .embed-responsive-xl-16by9::before {
    padding-top: 56.25%; }
  .embed-responsive-xl-4by3::before {
    padding-top: 75%; }
  .embed-responsive-xl-1by1::before {
    padding-top: 100%; }
  .embed-responsive-xl-3by2::before {
    padding-top: 66.66667%; } }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: ProximaNovaA-Regular, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2; }

.page-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00648c;
  background-image: url("../..//img/bg-body.jpg");
  background-repeat: no-repeat;
  background-size: cover; }

.page-container {
  position: relative;
  min-height: 100vh; }
  .page-container:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: radial-gradient(circle at bottom left, #012635, rgba(1, 38, 53, 0)); }

.content-wrapper {
  padding-bottom: 105px;
  position: relative; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding-bottom: 90px; } }

.main-footer {
  position: absolute;
  bottom: 0;
  width: 100%; }

[v-cloak] {
  display: none; }

.js-datetimepicker {
  background-color: white !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: ProximaNovaA-Bold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }
  h1.regular, h2.regular, h3.regular, h4.regular, h5.regular, h6.regular,
  .h1.regular, .h2.regular, .h3.regular, .h4.regular, .h5.regular, .h6.regular {
    font-family: ProximaNovaA-Regular, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }
  h1.bold, h2.bold, h3.bold, h4.bold, h5.bold, h6.bold,
  .h1.bold, .h2.bold, .h3.bold, .h4.bold, .h5.bold, .h6.bold {
    font-family: ProximaNovaA-Bold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }
  h1.semibold, h2.semibold, h3.semibold, h4.semibold, h5.semibold, h6.semibold,
  .h1.semibold, .h2.semibold, .h3.semibold, .h4.semibold, .h5.semibold, .h6.semibold {
    font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }
  h1.light, h2.light, h3.light, h4.light, h5.light, h6.light,
  .h1.light, .h2.light, .h3.light, .h4.light, .h5.light, .h6.light {
    font-family: ProximaNovaA-Light, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }

h1, .h1 {
  font-size: 20px; }
  @media screen and (min-width: 0px) {
    h1, .h1 {
      font-size: calc( 20px + 4 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      font-size: 24px; } }

h2, .h2 {
  font-size: 18px; }
  @media screen and (min-width: 0px) {
    h2, .h2 {
      font-size: calc( 18px + 4 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 22px; } }

h3, .h3 {
  font-size: 16px; }
  @media screen and (min-width: 0px) {
    h3, .h3 {
      font-size: calc( 16px + 4 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h3, .h3 {
      font-size: 20px; } }

h4, .h4 {
  font-size: 16px; }
  @media screen and (min-width: 0px) {
    h4, .h4 {
      font-size: calc( 16px + 2 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5 {
  font-size: 16px; }
  @media screen and (min-width: 0px) {
    h5, .h5 {
      font-size: calc( 16px + 0 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h5, .h5 {
      font-size: 16px; } }

h6, .h6 {
  font-size: 14px; }
  @media screen and (min-width: 0px) {
    h6, .h6 {
      font-size: calc( 14px + 2 * ((100vw - 0px) / 1200)); } }
  @media screen and (min-width: 1200px) {
    h6, .h6 {
      font-size: 16px; } }

em, .em {
  font-style: italic; }

strong, .strong {
  font-family: ProximaNovaA-Bold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.main-nav {
  background-color: #00648c;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3); }
  @media (min-width: 768px) {
    .main-nav {
      background-color: transparent;
      box-shadow: none; } }

.navbar {
  height: 64px;
  padding: 0;
  margin-bottom: 40px; }
  .navbar .navbar-brand {
    width: 105px;
    height: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00648c; }
  .navbar .user-dashboard .list-inline {
    margin-bottom: 0; }
    .navbar .user-dashboard .list-inline .list-inline-item:not(:last-child) {
      margin-right: 20px; }
  .navbar .user-dashboard .btn {
    color: #fff; }
    .navbar .user-dashboard .btn.is-active {
      text-decoration: underline; }
  .navbar__controls {
    display: flex;
    align-items: center; }
  @media (min-width: 768px) {
    .navbar {
      height: 112px;
      margin-bottom: 40px;
      justify-content: space-between; }
      .navbar .navbar-brand {
        width: 112px;
        padding: 0 8px; }
      .navbar .user-dashboard {
        padding-left: 25px; } }

.main-footer {
  height: 70px;
  margin-top: 20px;
  padding-top: 15px;
  background-color: #2e2e2e;
  color: #fff; }
  .main-footer .footer-nav {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .main-footer .footer-nav__item:not(:last-child):after {
      content: "|";
      display: inline-flex;
      margin: 5px; }
    .main-footer .footer-nav__link {
      color: inherit; }
      .main-footer .footer-nav__link:hover, .main-footer .footer-nav__link.active {
        text-decoration: underline; }
  @media (min-width: 768px) {
    .main-footer {
      padding-top: 0px; }
      .main-footer .footer-nav {
        justify-content: flex-end; }
        .main-footer .footer-nav__item:not(:last-child):after {
          content: "|";
          display: inline-flex;
          margin: 0 15px; } }

.btn {
  font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 0;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 17px;
  padding-right: 17px;
  line-height: 40px;
  line-height: 4rem;
  border: 0; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn__primary, .btn__secondary, .btn__tertiary {
    transition: all .2s ease; }
  .btn__primary {
    background-color: #00648c;
    color: #e7a218; }
    .btn__primary:hover {
      background-color: #0088bf;
      color: #e7a218; }
  .btn__secondary {
    background-color: #e7a218;
    color: #00648c; }
    .btn__secondary:hover {
      background-color: #ecb546;
      color: #00648c; }
  .btn__tertiary {
    background-color: #2e2e2e;
    color: #fff; }
    .btn__tertiary:hover {
      background-color: #484848;
      color: #fff; }
  .btn__text {
    padding: 0;
    font-size: 16px;
    font-size: 1.6rem;
    color: #00648c;
    outline: 0; }
    .btn__text:active, .btn__text:focus {
      outline: 0;
      box-shadow: none; }
    .btn__text:hover {
      color: #004059; }
  .btn__circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f9f9f9;
    border: 1px solid #b5b5b5;
    padding: 10px;
    margin-left: 15px;
    transform: rotate(0deg);
    transition: all .2s ease; }
    .btn__circle span {
      width: 75%;
      height: 2px;
      background-color: #00648c;
      position: absolute;
      top: 50%;
      left: 50%; }
      .btn__circle span:first-child {
        transform: translate(-50%, -50%) rotate(0deg);
        transition: transform .2s ease .2s; }
      .btn__circle span:last-child {
        transform: translate(-50%, -50%) rotate(90deg);
        transition: transform .2s ease .2s; }
    .btn__circle.is-active {
      transform: rotate(45deg); }
      .btn__circle.is-active span:first-child {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .btn__circle.is-active span:last-child {
        transform: translate(-50%, -50%) rotate(-45deg); }
    @media (min-width: 992px) {
      .btn__circle {
        width: 30px;
        height: 30px; } }
  .btn__tab {
    background-color: #00648c;
    color: #f9f9f9;
    padding: 0 15px;
    font-size: 16px;
    font-size: 1.6rem;
    outline: 0;
    transition: all .2s ease; }
    .btn__tab:active, .btn__tab:focus {
      outline: 0;
      box-shadow: none; }
    .btn__tab:hover {
      color: #e0e0e0; }
    .btn__tab.is-active {
      background-color: #f9f9f9;
      color: #00648c; }

a {
  color: #00648c; }

.a__back {
  background-color: transparent;
  border: 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 19px;
  color: #fff;
  text-transform: uppercase;
  transition: all .2s ease-out; }
  .a__back .icon {
    width: 14px;
    margin-right: 5px;
    position: absolute;
    left: 0; }
  .a__back:hover {
    color: #fff;
    text-decoration: none;
    padding-left: 10px; }
    .a__back:hover .icon {
      left: -5px; }

.icon {
  fill: currentColor; }
  .icon__chevron-left {
    transition: all .2s ease-out; }
  .icon__chevron-down {
    width: 13px;
    height: auto;
    transform: rotate(0deg);
    transition: transform .2s ease; }
    .icon__chevron-down.rotate {
      transform: rotate(180deg); }
  .icon__chevron-down-single {
    width: 13px;
    height: auto;
    transform: rotate(0deg);
    transition: transform .2s ease; }
    .icon__chevron-down-single.rotate {
      transform: rotate(180deg); }
  .icon__phone {
    width: 21px;
    height: auto; }

label, .form-text, .validation {
  padding: 0 10px; }

.form-text {
  margin-bottom: 1rem; }

.form-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 50px; }

label {
  margin: 0;
  font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1; }
  label.is-required:after {
    content: "*";
    color: #b00020;
    margin-left: 3px; }

.validation {
  display: inline-block; }
  .validation.field-validation-error {
    font-size: 14px;
    font-size: 1.4rem;
    font-style: italic;
    color: #b00020; }
  .validation.field-validation-valid {
    display: none; }

.form-control {
  width: 100%;
  max-width: 100%;
  height: auto;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  line-height: 2.6rem;
  background-color: #fff;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #b5b5b5;
  border-radius: 4px; }
  .form-control::-webkit-input-placeholder {
    color: rgba(46, 46, 46, 0.7);
    font-style: italic;
    opacity: 1; }
  .form-control:-moz-placeholder {
    color: rgba(46, 46, 46, 0.7);
    font-style: italic;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: rgba(46, 46, 46, 0.7);
    font-style: italic;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: rgba(46, 46, 46, 0.7);
    font-style: italic;
    opacity: 1; }
  .form-control.input-validation-error {
    border-color: #b00020; }

textarea {
  resize: vertical; }

.block {
  position: relative;
  z-index: 10;
  padding: 40px 15px 15px 15px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3); }
  .block__body {
    margin-top: 30px; }
    .block__body .controls {
      margin-top: 50px; }
  @media (min-width: 1200px) {
    .block:before {
      content: attr(data-product);
      font-family: ProximaNovaA-Bold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 110px;
      font-size: 11rem;
      letter-spacing: -10px;
      color: rgba(0, 100, 140, 0.5);
      mix-blend-mode: saturation;
      line-height: 1;
      position: absolute;
      bottom: calc(100% - 4rem);
      left: 75%;
      white-space: nowrap;
      pointer-events: none;
      width: 40vw; } }

.block-controls__top {
  position: relative;
  z-index: 20;
  padding-bottom: 10px; }

.block-controls__bottom {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px; }

@media (min-width: 768px) {
  .block-controls__bottom {
    justify-content: flex-end; } }

.responsive-table {
  width: 100%;
  overflow: hidden; }
  .responsive-table tr {
    border-color: #46637f; }
  .responsive-table th, .responsive-table td:before {
    color: #000; }
  .responsive-table thead tr {
    border-bottom: 1px solid #ddd; }
  .responsive-table tbody .td__view .btn, .responsive-table tbody .td__archive .btn, .responsive-table tbody .td__edit .btn {
    font-family: ProximaNovaA-Regular, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
    color: #000;
    line-height: 1;
    text-decoration: underline; }
    .responsive-table tbody .td__view .btn:hover, .responsive-table tbody .td__archive .btn:hover, .responsive-table tbody .td__edit .btn:hover {
      color: #004059; }
  .responsive-table th {
    display: none; }
  .responsive-table th, .responsive-table td:before {
    font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }
  .responsive-table td {
    display: block;
    padding: 5px; }
    .responsive-table td:before {
      content: attr(data-th);
      width: 25%;
      display: inline-block; }
  .responsive-table th, .responsive-table td {
    text-align: left;
    padding: 5px; }
  .responsive-table.striped tbody tr:nth-child(2n) {
    background-color: rgba(0, 100, 140, 0.2); }
  @media (min-width: 992px) {
    .responsive-table td:before {
      display: none; }
    .responsive-table th, .responsive-table td {
      display: table-cell; } }

.state-indicator {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  background-color: #c8c8c8;
  margin-right: 5px; }
  .state-indicator--processing {
    background-color: #c8c8c8; }
  .state-indicator--confirmed {
    background-color: #1fc017; }
  .state-indicator--accepted {
    background-color: #1fc017; }
  .state-indicator--canceled {
    background-color: #a70b0b; }
  .state-indicator--completed {
    background-color: #1fc017; }

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity .3s ease; }

.modal__container {
  max-width: 500px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  transition: all .3s ease; }

.modal__header {
  margin-bottom: 30px; }
  .modal__header .title {
    color: #00648c; }

.modal__body {
  margin-bottom: 40px; }

.modal-enter-active {
  transition: all .3s ease; }

.modal-enter,
.modal-enter-active,
.modal-leave-active {
  opacity: 0; }

.modal-enter .modal__container,
.modal-enter-active .modal__container,
.modal-leave-active .modal__container {
  transform: scale(1.1); }

.divider {
  width: 70%;
  display: flex;
  justify-content: center;
  position: relative; }
  .divider .text {
    background-color: #f9f9f9;
    position: relative;
    z-index: 10;
    padding: 15px; }
  .divider hr {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s; }

.fade-enter {
  opacity: 0; }

.admin-nav .level-0__item button, .admin-nav .level-0__item a, .admin-nav level-1__item button, .admin-nav level-1__item a {
  color: #fff;
  text-decoration: none; }
  .admin-nav .level-0__item button:hover, .admin-nav .level-0__item a:hover, .admin-nav level-1__item button:hover, .admin-nav level-1__item a:hover {
    text-decoration: underline; }

.admin-nav .level-0__item:not(:first-child) {
  margin-top: 25px; }

.admin-nav .level-0__item button, .admin-nav .level-0__item a {
  font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.admin-nav .level-1 {
  padding-left: 15px; }
  .admin-nav .level-1__item:not(:first-child) {
    margin-top: 5px; }
  .admin-nav .level-1__item button, .admin-nav .level-1__item a {
    font-family: ProximaNovaA-Regular, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal; }

.pagination {
  margin-top: 25px; }
  .pagination .page-item .page-link {
    padding: 5px 10px;
    color: #00648c; }
  .pagination .page-item.disabled .page-link {
    color: #b5b5b5; }

.toggle {
  display: block;
  text-align: center;
  user-select: none; }

.toggle--checkbox {
  display: none; }

.toggle--btn {
  display: block;
  margin: 0 auto;
  font-size: 1.4em;
  transition: all 350ms ease-in; }
  .toggle--btn:hover {
    cursor: pointer; }

.toggle--btn:before, .toggle--btn:after,
.toggle--checkbox:before,
.toggle--checkbox:after,
.toggle--feature:before,
.toggle--feature:after {
  content: '';
  display: block; }

.toggle--switch .toggle--btn {
  position: relative;
  width: 65px;
  height: 24px;
  text-transform: uppercase;
  color: #000;
  background-color: #fff; }
  .toggle--switch .toggle--btn, .toggle--switch .toggle--btn:before {
    border-radius: calc(24px / 2); }
  .toggle--switch .toggle--btn:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url("../..//img/icon-en.svg");
    background-size: cover;
    background-position: center;
    text-indent: -100%; }

.toggle--switch .toggle--feature {
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;
  display: block;
  overflow: hidden;
  height: 24px; }
  .toggle--switch .toggle--feature:before, .toggle--switch .toggle--feature:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .toggle--switch .toggle--feature:before {
    content: attr(data-label-on);
    left: -60%; }
  .toggle--switch .toggle--feature:after {
    content: attr(data-label-off);
    right: 16%; }

.toggle--switch .toggle--checkbox:checked + .toggle--btn {
  background-position: 0 0; }
  .toggle--switch .toggle--checkbox:checked + .toggle--btn:before {
    left: calc(100% - (18px + 3px));
    background-image: url("../..//img/icon-nl.svg"); }
  .toggle--switch .toggle--checkbox:checked + .toggle--btn .toggle--feature:before {
    left: 20%; }
  .toggle--switch .toggle--checkbox:checked + .toggle--btn .toggle--feature:after {
    right: -60%; }

.user-dashboard-detail .state-indicator {
  position: absolute;
  top: 15px;
  right: 15px;
  margin: 0; }

.user-dashboard-detail .order-details table tr td:before {
  margin-right: 10px; }

.admin-nav {
  padding: 30px;
  height: 100%; }

.admin-body {
  padding: 30px;
  height: 100%; }

.color-white {
  color: #fff !important; }

.color-black {
  color: #000 !important; }

.color-brand-primary {
  color: #00648c !important; }

.color-brand-secondary {
  color: #e7a218 !important; }

.color-brand-tertiary {
  color: #2e2e2e !important; }

.color-dark-red {
  color: #b00020 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-light {
  background-color: #f9f9f9 !important; }

.bg-brand-primary {
  background-color: #00648c !important; }

.bg-brand-secondary {
  background-color: #e7a218 !important; }

.bg-brand-tertiary {
  background-color: #2e2e2e !important; }

.font-light {
  font-family: ProximaNovaA-Light, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.font-regular {
  font-family: ProximaNovaA-Regular, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.font-semibold {
  font-family: ProximaNovaA-Semibold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.font-bold {
  font-family: ProximaNovaA-Bold, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

.text-tiny {
  font-size: 12px;
  font-size: 1.2rem; }

.text-small {
  font-size: 14px;
  font-size: 1.4rem; }

.text-regular {
  font-size: 16px;
  font-size: 1.6rem; }

.text-medium {
  font-size: 18px;
  font-size: 1.8rem; }

.info {
  position: relative;
  overflow: hidden;
  max-height: 400px;
  background: #eee; }
  .info__inner {
    padding: 15px; }

.other {
  background-color: lightgreen;
  padding: 5px; }

.slide-enter-active,
.slide-leave-active {
  max-height: 400px;
  transition: all 0.3s ease-out; }

.slide-enter,
.slide-leave-to {
  max-height: 0px;
  opacity: 0; }

.toggleSlide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in; }

.toggleSlide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.toggleSlide-enter-to, .toggleSlide-leave {
  max-height: 100px;
  overflow: hidden; }

.toggleSlide-enter, .toggleSlide-leave-to {
  overflow: hidden;
  max-height: 0; }
